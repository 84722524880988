import PrintButton from "./PrintButton";

function App() {
  return (
    <>
      <PrintButton />
    </>
  );
}

export default App;
